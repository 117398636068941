import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EditorialPage from "../components/EditorialPage/EditorialPage"

const Editorial = () => {
  return (
    <Layout search>
      <SEO
        title="Editorial"
        description="The latest design news and collections from around the world."
        url={`${process.env.GATSBY_WEBSITE_DOMAIN}/editorial/`}
      />
      <EditorialPage />
    </Layout>
  )
}

export default Editorial
