import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';

export const EditorialPageContainer = styled.div`
	/* overflow: hidden; */

	@media ${device.tablet} {
	}

	@media ${device.desktop} {
	}

	.topAd {
		margin: 30px auto;

		@media ${device.desktop} {
			margin: 60px auto;
		}
	}
`;
