import React, { useState, useEffect, useCallback, useRef } from "react"
import { EditorialPageContainer } from "./style.js"
import { EditorialSmallGrid, SliderGrid } from "../TeaserGrids/index.js"
import { Teaser } from "../Teaser/index.js"
import { AdvertisementWrapper } from "../AdvertisementWrapper/"
import { GoogleAd } from "../GoogleAd/index.js"
import EditoriaBigTeaser from "../Teaser/editorialBigTeaser"
import { LatestEditorialsData } from "../../hooks/latestEditorials"
import { Pagination } from "../Pagination/index.js"
import { BottomPagination, Number } from "../SearchPage/style.js"
import algoliasearch from "algoliasearch"
// import { ResultsGrid } from "../TeaserGrids/index.js"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const EditorialPage = props => {
  const isFirstRun = useRef(true)
  const postsPerPage = 17
  const [posts, setPosts] = useState(LatestEditorialsData().posts)
  const [totalPosts] = useState(LatestEditorialsData().count)
  const [currentPage, setCurrentPage] = useState(1)
  const lastPage = Math.ceil(totalPosts / postsPerPage)

  /**** ALGOLIA DEV ****/

  const findResults = useCallback(() => {
    index.search(
      {
        page: currentPage - 1,
        facetFilters: [[`type: editorial`]],
        hitsPerPage: 17,
      },
      (err, data = {}) => {
        if (err) throw err
        setPosts(data.hits)
        console.log(data.hits)
      }
    )
  }, [currentPage])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    findResults()
  }, [findResults])

  const firstEditorial = posts[0]
  const secondaryEditorials = posts.slice(1, 5)
  const tertiaryEditorials = posts.slice(5, 17)
  console.log("posts:", posts)

  const rgb =
    firstEditorial.colourFeatureImage &&
    JSON.parse(firstEditorial.colourFeatureImage).join()

  return (
    <EditorialPageContainer>
      <AdvertisementWrapper horizontal className="topAd" id="topAd">
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["5080216920", "2454053585", "2814459313"]}
        />
      </AdvertisementWrapper>
      {/* <TopIndicator hide={currentPage === 1}>
        <Number className="number">
          <p>
            <span>{totalPosts}</span> result{totalPosts === 1 ? null : "s"}{" "}
            found. Showing page <span> {currentPage}</span> of{" "}
            <span>{lastPage}</span>.
          </p>
        </Number>
      </TopIndicator> */}

      <EditoriaBigTeaser
        title={firstEditorial.title}
        date={firstEditorial.date}
        featuredImage={firstEditorial.featuredImage || firstEditorial.image}
        link={firstEditorial.link}
        categories={
          firstEditorial.categories.nodes || firstEditorial.categoriesFull
        }
        excerpt={firstEditorial.excerpt}
        colourFeatureImage={rgb}
      />

      <SliderGrid editorial>
        {secondaryEditorials.map((teaser, index) => {
          return (
            <Teaser
              key={index}
              standard
              title={teaser.title}
              img={teaser.featuredImage || teaser.image}
              link={teaser.link}
              categories={teaser.categories.nodes || firstEditorial.categoriesFull}
              color={teaser.colourFeatureImage}
            />
          )
        })}
      </SliderGrid>
      <EditorialSmallGrid hide={posts.length <= 5}>
        {tertiaryEditorials.map((teaser, index) => {
          return (
            <Teaser
              link={teaser.link}
              key={index}
              title={teaser.title}
              little
              img={teaser.featuredImage || teaser.image}
              color={teaser.colourFeatureImage}
            />
          )
        })}

        {/* {placeholderTeasers.map((teaser, index) => {
          return (
            <Teaser
              key={index}
              title="Everything you need to know about skeleton screens"
              little
              secondColumn
              slug="editorial"
            />
          )
        })} */}

        <AdvertisementWrapper editorialVertical hide={posts.length <= 11}>
          <GoogleAd
            className="vertical"
            client="ca-pub-4461681428787239"
            slots={["2100143444"]}
          />{" "}
        </AdvertisementWrapper>
      </EditorialSmallGrid>

      <BottomPagination category hide={posts.length <= 11}>
        <Number className="number">
          <p>
            <span>{totalPosts}</span> result{totalPosts === 1 ? null : "s"}{" "}
            found. Showing page <span> {currentPage}</span> of{" "}
            <span>{lastPage}</span>.
          </p>
        </Number>

        <Pagination
          scrollId="topAd"
          topOfElement
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          lastPage={lastPage}
          totalPosts={totalPosts}
          bottom
          editorial
        />
      </BottomPagination>

      <AdvertisementWrapper horizontal>
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["7514808570", "4888645237", "4016199658"]}
        />
      </AdvertisementWrapper>
      {/* <EditoriaBigTeaser imageReverse /> */}
    </EditorialPageContainer>
  )
}

export default EditorialPage
